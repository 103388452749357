<template>
  <section class="aboutus">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <BreadCrumb :options="options"></BreadCrumb>
    <div class="container">
      <!-- <div class="aboutus_bread">
        <el-breadcrumb  separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item><a href="/aboutus">关于我们</a></el-breadcrumb-item>
        </el-breadcrumb>
      </div> -->
      <el-tabs :tab-position="tabPosition" class="tab" v-model="editableTabsValue">
        <el-tab-pane class="aboutus_my" name="aboutus_my" label="关于我们" :disabled="isDisabled"></el-tab-pane>
        <el-tab-pane class="aboutus_introduce" name="aboutus_introduce" label="公司简介" :lazy="lazy">
          <section class="article">
            <!-- <img src="../assets/images/aboutus/introduce/1.png" alt=""> -->
            <!-- <p>上海旋荣科技股份有限公司成立于2013年，于2016年在新三板挂牌，2020年跻身于新三板创新层，是传统和新型电力系统生产物资、电力设备和配套系统的供应商，同时也是一体化信息系统和供应链赋能服务的提供商，公司拥有专业的电气自动化，信息化和物联网技术的研发团队，在状态全面感知、数据可靠传输、机器深度学习、视觉成像分析和组件及接口开发方面掌握了核心技术，并努力成为所在领域内的领先企业。</p>
            <p>公司拥有专业的电气自动化，信息化和物联网技术的研发团队，在状态全面感知、数据可靠传输、机器深度学习、视觉成像分析和组件及接口开发方面掌握了核心技术。公司以此为依托，聚焦电力行业，自主研发智能变电站辅助系统综合监控平台，特高压重症监护平台，微环境（温湿度、积水）状态监测平台等系列产品。产品应用覆盖包括特高压，超高压，中压，低压在内的全域和全电压等级设备，以及变电站全站的辅控系统，同时公司持续进行产品迭代和新产品的研发，以满足电力系统的最新要求和新型电力系统所带来的变革、挑战和机遇。</p>
            <p>公司同为工器具、仪器仪表、办公物资等MRO类产品和信创类服务器、PC产品的集约化供应商，并从事部分上述产品的研发、OEM贴牌生产、销售及相关技术服务。公司通过批量采购（经销）以及OEM贴牌生产的方式集约化的为用户提供55大类、8万余种SKU的工器具、仪器仪表、办公物资等MRO系列产品，并积累了同类产品大量的原厂资源，形成了包含工器具、仪器仪表、个性化办公物资等可对外赋能的供应链体系。目前业务主要服务于电力、能源、金融行业相关的央国企。</p>
            <p>公司团队具有丰富的央企电商化项目履约经验和深厚的互联网领域技术积累，自主研发了《助力飞现代智慧供应链》系列产品，助力飞是具有独创性的供应链双向赋能的一体化信息管理平台，主要服务于大型企业自建电商平台业务和中小企业的数字化转型业务。</p>
            <p>助力飞系列产品包括具有完全自主知识产权的S2B2C供应链新零售系统，B2B2C多用户商城系统、B2C商城系统、直播商城系统、O2O门店系统、B2B商城系统、APP商城系统、微商城 小程序系统、收银系统、骑手配送系统、跨境电商等电子商务软件系统，DDS系统，OA系统，CRM系统，项目管理系统，人员工时管理系统，人员绩效管理系统，进销存系统，WMS系统，TMS系统，厂家直供平台，商品管理平台等子系统，旨在打造企业版“拼多多”和基于多用户实时富余库存的电商平台。并取得60多项著作权及相关证书。</p> -->
            <el-row :gutter="30">
              <el-col :span="12">
                <p>上海旋荣科技股份有限公司成立于2013年，于2016年在新三板挂牌，2020年跻身于新三板创新层，是传统和新型电力系统生产物资、电力设备和配套系统的供应商，同时也是一体化信息系统和供应链赋能服务的提供商，公司拥有专业的电气自动化，信息化和物联网技术的研发团队，在状态全面感知、数据可靠传输、机器深度学习、视觉成像分析和组件及接口开发方面掌握了核心技术，并努力成为所在领域内的领先企业。</p>
                <p>公司拥有专业的电气自动化，信息化和物联网技术的研发团队，在状态全面感知、数据可靠传输、机器深度学习、视觉成像分析和组件及接口开发方面掌握了核心技术。公司以此为依托，聚焦电力行业，自主研发智能变电站辅助系统综合监控平台，特高压重症监护平台，微环境（温湿度、积水）状态监测平台等系列产品。产品应用覆盖包括特高压，超高压，中压，低压在内的全域和全电压等级设备，以及变电站全站的辅控系统，同时公司持续进行产品迭代和新产品的研发，以满足电力系统的最新要求和新型电力系统所带来的变革、挑战和机遇。</p>
                <p>公司同为工器具、仪器仪表、办公物资等MRO类产品和信创类服务器、PC产品的集约化供应商，并从事部分上述产品的研发、OEM贴牌生产、销售及相关技术服务。公司通过批量采购（经销）以及OEM贴牌生产的方式集约化的为用户提供55大类、8万余种SKU的工器具、仪器仪表、办公物资等MRO系列产品，并积累了同类产品大量的原厂资源，形成了包含工器具、仪器仪表、个性化办公物资等可对外赋能的供应链体系。目前业务主要服务于电力、能源、金融行业相关的央国企。</p>
                <p>公司团队具有丰富的央企电商化项目履约经验和深厚的互联网领域技术积累，自主研发了《助力飞现代智慧供应链》系列产品，助力飞是具有独创性的供应链双向赋能的一体化信息管理平台，主要服务于大型企业自建电商平台业务和中小企业的数字化转型业务。</p>
                <p>助力飞系列产品包括具有完全自主知识产权的S2B2C供应链新零售系统，B2B2C多用户商城系统、B2C商城系统、直播商城系统、O2O门店系统、B2B商城系统、APP商城系统、微商城 小程序系统、收银系统、骑手配送系统、跨境电商等电子商务软件系统，DDS系统，OA系统，CRM系统，项目管理系统，人员工时管理系统，人员绩效管理系统，进销存系统，WMS系统，TMS系统，厂家直供平台，商品管理平台等子系统，旨在打造企业版“拼多多”和基于多用户实时富余库存的电商平台。并取得60多项著作权及相关证书。</p>
              </el-col>
              <el-col :span="12">
                <img src="../assets/images/aboutus/正文配图.jpg" alt="">
              </el-col>
            </el-row>
          </section>
        </el-tab-pane>
        <el-tab-pane class="aboutus_culture" name="aboutus_culture" label="公司文化" :lazy="lazy">
          <section class="article article-content">
            <!-- <img src="../assets/images/aboutus/culture/1.png" alt=""> -->
            <p>旋荣科技秉承“您的需要，我们承诺”（Promise For Need）的经营理念，为客户提供全方面的服务，始终把客户的需要放在第一位，想客户之所想，急客户之所急。公司不仅在传统物资供应、软件研发和技术服务上具备技术优势，同时为客户提供全方位的增值服务，从而实现客户的精细化管理和精益收入。</p>
            <img src="../assets/images/aboutus/culture/组织架构.png" alt="">
          </section>
        </el-tab-pane>
        <el-tab-pane class="aboutus_honor" name="aboutus_honor" label="公司荣誉" :lazy="lazy">
          <section class="article">
            <el-row>
              <el-col :span="16"><div class="left">
                <el-card :body-style="{ padding: '0px'}" v-for="(item, index) in cardDate1" :key="index" shadow="never">
                  <img :src="item.src" class="image">
                  <div style="padding: 14px;font-size: 1rem;"><span>{{item.title}}</span></div>
                </el-card>
              </div></el-col>
              <el-col :span="8"><div class="right">
                <el-card :body-style="{ padding: '0px'}" v-for="(item, index) in cardDate2" :key="index" shadow="never">
                  <img :src="item.src" class="image">
                  <div style="padding: 14px;font-size: 1rem;"><span>{{item.title}}</span></div>
                </el-card>
              </div></el-col>
            </el-row>
          </section>
        </el-tab-pane>
        <el-tab-pane class="aboutus_grow" name="aboutus_grow" label="成长轨迹" :lazy="lazy">
          <section class="article">
            <h6>不积跬步，无以至千里；不积小流，无以成江海</h6>
            <ul>
              <li v-for="(item, index) in activities" :key="index" :class="index % 2 == 0 ? 'even' : 'odd'">
                <span class="point" v-if="index % 2 == 1"><i class="red"></i><i class="blue"></i></span>
                <span class="point" v-else><i class="blue"></i><i class="red"></i></span>
                <strong class="title">{{ item.title }}</strong>
                <div style="padding: 0 2rem;">
                  <p><i class="zheng"></i></p>
                  <p v-for="(org, i) in item.content" :key="i">{{org}}</p>
                </div>
              </li>
            </ul>
          </section>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
  import imageBanner from '../components/ImageBanner.vue'
  import BreadCrumb from '../components/BreadCrumb.vue';
  export default {
    name: 'aboutus',
    components: {imageBanner, BreadCrumb},
    data () {
      return {
        options: [{name: '首页', url: '/'}, {name: '关于我们', url: '/aboutus'}],
        tabPosition: 'left',
        isDisabled: true,
        editableTabsValue : 'aboutus_introduce',
        lazy: true,
        cardDate1: [
          {src: require('../assets/images/aboutus/honor/1.png'), title: '2016年度先进企业奖'},
          {src: require('../assets/images/aboutus/honor/2.png'), title: '2017年度纳税贡献奖'},
          {src: require('../assets/images/aboutus/honor/3.png'), title: '大学生实习基地'},
          {src: require('../assets/images/aboutus/honor/4.png'), title: '先进基层党组织'},
        ],
        cardDate2: [
          {src: require('../assets/images/aboutus/honor/5.png'), title: '2018年度纳税贡献一等奖'},
        ],
        imgBannerObj: {
          url: require('../assets/images/aboutus/02-关于我们.png')
        },
        activities: [
          {
            title: "2013年",
            content: ['4月8日公司注册成立', '《发票数据管理软件》系列产品研发面世，服务央企，服务中小企业', '助力飞第一代（助力飞G1）产品面世，C/S架构，主要用于公司内部管理，以进销存的功能为主'],
            type: "primary",
            icon: "el-icon-check",
            linecss: "line1"
          },

          {
            title: "2014年",
            content: ['参加央企的电商化项目的电商平台的研发', '参与央企办公类物资电商化项目的实际业务履约', ],
            type: "primary",
            icon: "el-icon-check",
            linecss: "line1"
          },

          {
            title: "2015年",
            content: ['助力飞G2上线，整体转型为B/S架构，在原有基础上对功能进行升级，主要包括：商品比价，毛利审批等OA辅助决策功能', '完成股份制改革，更名为上海旋荣科技股份有限公司', '经销商发票监管软件开发完成正式上线，首批上线的包括长安福特、上汽大众、捷豹路虎等知名汽车厂商'],
            type: "primary",
            icon: "el-icon-check",
            linecss: "line2"
          },
          {
            title: "2016年",
            content: ['挂牌新三板，证券代码：838956', '新增参与电力仪器仪表，工器具，辅助设备设施，低压电器等电商化项目的履约', '电子发票系统上线，雅诗兰黛、艾默生、春秋航空、震旦、安联保险、通联支付、赫基服饰、芝禾商贸等项目相继上线'],
            type: "warning",
            icon: "el-icon-minus",
            linecss: "line3"
          },
          {
            title: "2017年",
            content: ['南京分部正式成立，作为公司的辅助研发中心', '成立产品部，开始中高端产品自主研发', '由产品部主导部分仪器仪表、工器具产品的OEM贴牌生产', '票秘书电子发票开票APP上线', '助力飞3G上线：并升级为SaaS架构，并着力完善运营协同板块，初步实现以销定采，销售机会管理，采购响应回溯、审批等一体化管理的模式', ],
            type: "info",
            icon: "el-icon-close",
            linecss: "line3"
          },
          {
            title: "2018年",
            content: ['设立全资子公司上海旋胜科技有限公司，作为公司的独立研发中心而设立', '变电宝产品上线，旨在提高变电站范围内设备的运行、检修的一体化管理效率', '南京分部办公地址搬迁，南京运维中心正式成立', '上海旋荣科技股份有限公司党支部成立', '上海旋荣科技股份有限公司工会成立', '参与军网商城（联勤保障中心）的项目履约', '助力飞3G：根据业务的升级，不断升级和完善助力飞的功能', '加大有关微环境（温湿度、积水）状态监测平台的产品研发', ],
            type: "info",
            icon: "el-icon-close",
            linecss: "line3"
          },
          {
            title: "2019年",
            content: ['深圳分公司成立，拓展南方市场业务，对接深圳研发的合作伙伴', '山东分公司成立，拓展山东市场', '自主研发电力设备微环境整治系统上线，并迅速打开市场', '助力飞4G上线：“企业版拼多多”模式和基于共享商城模式上线，进一步丰富助力飞的供应链上下游赋能功能并对商业模式进行进一步验证', ],
            type: "info",
            icon: "el-icon-close",
            linecss: "line3"
          },
          {
            title: "2020年",
            content: ['2020年初疫情肆虐，筹集了数千个口罩，捐赠给南京东路街道、南京东路派出所和武汉幸福社区工作人员等单位，藉此向所有抗疫一线的工作人员致敬。', '核心员工认定，并进行首轮的员工股权激励', '升级为新三板创新层企业', '微环境整治系统升级为：微环境（温湿度、积水）状态监测平台，配套的系列自研产品有：双组分有机硅电柜专用防潮胶，半导体冷凝除湿系统、LORA微环境嵌入式监控系统，气体浓度、水分嵌入式监控系统。主要应用于特高压的环境和设备中。', '助力飞4G在同行业中进行推广，取得良好的效果，同时根据用户的新增需求，不断丰富助力飞的功能'],
            type: "info",
            icon: "el-icon-close",
            linecss: "line3"
          },
          {
            title: "2021年",
            content: ['公司主导进行了行业标准、团体标准的制订：《国有企业电子商务办公和MRO类物资通用管理要求》', '上海旋荣科技股份有限公司党支部被南京东路街道评为“先进基层党组织”', '河南郑州爆发特大暴雨灾害，参与国网上海市电力公司在郑州地区电力抢修的物资供应工作', '助力飞5G上线：新增电子商城板块，打通了内部管理和电商平台，既给电商平台赋予一个强大的后台，又给共享库存开辟了一个新的业务出口，使得助力飞商业模式更加完善。同时新增生产制造板块和项目管理板块，使得一体化的管理范围的到更大的扩展，大大增强系统的实用性和效率', '变电站智能辅控平台经过多年研发后正式面世，参照最新标准研发，且通过CNAS实验室认证', ],
            type: "info",
            icon: "el-icon-close",
            linecss: "line3"
          },
          {
            title: "2022年",
            content: ['2022年3~5月积极参加上海地区的疫情防控，获得街道基层政府组织的表扬', ],
            type: "info",
            icon: "el-icon-close",
            linecss: "line3"
          }
        ]
      }
    },
    watch: {
      $route () {
        this.getEditableTabsValue()
      }
    },
    created () {
      this.getEditableTabsValue()
    },
    methods: {
      getEditableTabsValue () {
        switch (this.$route.query.id) {
          case 'introduce':
            this.editableTabsValue = 'aboutus_introduce'
            break
          case 'culture':
            this.editableTabsValue = 'aboutus_culture'
            break
          case 'honor':
            this.editableTabsValue = 'aboutus_honor'
            break
          case 'grow':
            this.editableTabsValue = 'aboutus_grow'
            break
          default:
            this.editableTabsValue = 'aboutus_introduce'
        }
      }
    }
  };
</script>

<style scoped>
.aboutus{}
.aboutus img{width:100%;}
.aboutus .article{background-color:#fff; padding:2rem;min-height: 20rem;}
.aboutus .article.article-content img {width:85%;}
.aboutus .article p {text-indent:2em;padding:.5rem 0;font-size: 1.2rem; line-height: 1.6; text-align: left; text-indent: 2em;}
.aboutus_conent {max-width:1920px;margin:0 auto; background-color:#ecf0f5;padding:1rem 0; }
.aboutus .aboutus_bread {display: block; width:100% ;float: right; padding: 1rem 0; background-color: #fff;}
.tab{font-size:2rem;margin:1rem 10%; background-color: #fff;}
.aboutus .left .el-card{width:48%;display:inline-block;float:left;box-sizing: border-box; padding:2rem; border:none; width: 100%; box-sizing: border-box;}
.aboutus .right .el-card .image{width:60%; margin-top: 3rem;}
/* .aboutus /deep/ .tab .el-tabs__nav{background-color:#fff;}
.aboutus /deep/ .el-tabs--left .el-tabs__item{padding:.5rem 1rem !important;text-align:center !important;font-size:1rem;height:auto !important;line-height:auto !important;}
.aboutus /deep/ .tab .el-tabs__item.is-active{background-color:#a2bee7;color:#fff;font-weight:600;}
.aboutus /deep/ .tab .el-tabs__item.is-disabled{font-size:1.6rem;padding:1.4rem 2rem;background-color:#7895be !important;color:#fff;height:auto;}
.aboutus /deep/ .el-tabs--left .el-tabs__active-bar{height:0 !important;} */
.aboutus_grow .article {position: relative;}
.aboutus_grow .article h6 {margin-bottom: 1rem; font-weight:600; font-size: 2rem;}
.aboutus_grow .article .title {color: #0D6BAB;}
.aboutus_grow .article .point {position: absolute;}
.aboutus_grow .article p {font-size: 1rem; color: #666; line-height: 1.4; text-indent: 0;}
.aboutus_grow .article .zheng {display: inline-block; width: 1rem; height: 1rem;}
.aboutus_grow .article .odd, .aboutus_grow .article .even {width: 48%; min-height: 10rem; padding: 0.5rem;}
.aboutus_grow .article .odd {transform: translate(100% , 0); border-left: .6rem solid #0D6BAB; text-align: left;}
.aboutus_grow .article .odd .point {left: -.1rem;}
.aboutus_grow .article .odd .title {margin-left: 2rem;}
.aboutus_grow .article .odd .zheng {background-color:#E56B15;}
.aboutus_grow .article .even {transform: translate(.55rem, 0); border-right: .6rem solid #0D6BAB; text-align: right;}
.aboutus_grow .article .even .point {right: -.1rem;}
.aboutus_grow .article .even .title {margin-right: 2rem;}
.aboutus_grow .article .even .zheng {background-color:#0D6BAB;}
.red {display: inline-block; width: .4rem; height: .8rem; background-color: #E50014;}
.blue {display: inline-block; width: 1.6rem; height: .8rem; background-color: #0D6BAB;}

/* .aboutus /deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {padding-left: 1rem;}
.aboutus /deep/ .el-tabs--top .el-tabs__item.is-top:last-child {padding-right: 1rem;} */
</style>